* {
	box-sizing: border-box;
}

html, 
body {
	width: 100vw;
	min-height: 100vh;
	margin: 0;
	padding: 0;
	font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	line-height: 1.5;
  background-color: #333;
  color: #ddd;
}

a {
	color: inherit;
	text-decoration: none;
}